import axiosStatic, { AxiosError } from "axios";
import { apiBaseUrl } from "./env";

export const api = axiosStatic.create({ baseURL: apiBaseUrl });

export const getErrorMessage = (error: AxiosError<IApiErrorResponse>): string =>
  error.response
    ? typeof error.response === "string"
      ? error.response
      : "message" in error.response.data
        ? error.response.data.message
        : JSON.stringify(error.response.data)
    : error.message;
