export const formatMoney = (number: number | undefined) => {
  if (number === undefined || number === null) return "$-";
  return "$" + number.toLocaleString(
    "en",
    { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 });
};

export const formatNumber = (number: number | undefined) => {
  if (number === undefined || number === null) return "";
  return number.toLocaleString(
    "en",
    { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 });
};

export const titleCase = (value: string) => {
  const str = value.toLowerCase().split(' ');
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(' ');
}
