import { store } from 'react-notifications-component';

const createNotification = (type: 'danger' | 'warning' | 'success', message: string) => {
  store.addNotification({
    message,
    type,
    container: 'top-right',
    dismiss: {
      duration: 5000,
      pauseOnHover: true,
      showIcon: true,
      click: true,
      touch: true,
    }
  });
}

export const notify = {
  error: (message: string) => createNotification('danger', message),
  warning: (message: string) => createNotification('warning', message),
  success: (message: string) => createNotification('success', message),
}