import InfiniteScroll from "react-infinite-scroll-component";
import { Card, Image } from "semantic-ui-react";
import ShowMoreText from "react-show-more-text";
import { formatMoney, titleCase } from "./services/format";
import { matchProtocol } from "./services/web";

const PropertyList: React.FC<IPropertyListProps> = ({ properties, hasMore, next }) => {
  const showMediumRes = window.innerWidth < 512;
  return <Card.Group as={InfiniteScroll} dataLength={properties.length} hasMore={hasMore} next={next}>
    {properties.map(p => <Card key={p.id} as="article" fluid>
      {p.pictures.length > 0 && (
        <Image wrapped ui={false}
          src={matchProtocol(showMediumRes ? p.pictures[0].mediumUrl : p.pictures[0].url)}
          alt={p.pictures[0].description} title={p.pictures[0].description} />
      )}
      <Card.Content>
        <Card.Header content={p.propertyType + ' in ' + chooseField(p.legalSubdivision, p.subdivisionName, p.city, p.county)} />
        <Card.Meta content={'MLS #' + p.listingId} />
        <Card.Description>
          <b>{p.status}</b>
          <ShowMoreText less={null} keepNewLines>
            {p.publicRemarks}
          </ShowMoreText>
          {!!p.listPrice && <p>Offered for {formatMoney(p.listPrice)}</p>}
          {!!p.listOfficeName && <small>Courtesy of {p.listOfficeName}</small>}
        </Card.Description>
      </Card.Content>
    </Card>)}
  </Card.Group>;
};

export default PropertyList;

interface IPropertyListProps {
  properties: IPropertySummary[];
  hasMore: boolean;
  next: () => void;
}

const chooseField = (...values: Array<string | undefined>) => {
  for (let i = 0; i < values.length; i++) {
    const value = values[i];
    if (value && !value.toUpperCase().includes('TEST')) {
      return titleCase(value);
    }
  }
  return '';
};