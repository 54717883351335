import { Loader } from 'semantic-ui-react';
import './App.css';
import useProperties from './hooks/useProperties';
import Logo from './Logo';
import PropertyList from './PropertyList';

function App() {
  const { properties, loading, hasMore, next } = useProperties();

  return (
    <main>
      <Logo />
      {properties
        ? properties.length
          ? <PropertyList properties={properties} hasMore={hasMore} next={next} />
          : <div>We have currently no property listed for sale or auction.</div>
        : null}
      {loading && <div className="spin"><Loader inline active /></div>}
    </main>
  );
}

export default App;
